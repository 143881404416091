.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.sectionsmall,
.sectionmedium, 
.sectionlarge {
    display: flex;
    flex-direction: column;
}

.inputRow {
    display: flex;
    flex-direction: row;
}

.inputRow label {
    width: 20%;
    max-width: 150px;
    padding: 10px;
}

.inputRow input,
select {
    margin: 10px;
    min-width: 150px;
}

.inputRow button {
    margin: 10px;
}

@media screen and (orientation: landscape) {
    .sectionsmall {
        flex-basis: 25%;
        flex-grow: 100%;
        flex-shrink: 100%;
    }
    .sectionmedium {
        flex-basis: 75%;
        flex-grow: 100%;
        flex-shrink: 100%;
    }
    .sectionlarge {
        flex-basis: 100%;
        flex-grow: 100%;
        flex-shrink: 100%;
    }
}

@media screen and (orientation: portrait) {
    .sectionsmall,
    .sectionmedium,
    .sectionlarge {
        flex-basis: 100%;
        flex-grow: 100%;
        flex-shrink: 100%;
    }
}